import React, { useState } from 'react';
import { MainLayout } from '@landing/layouts';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Client } from '@landing/pageComponents/ClientPage';
import { API_URL } from '@landing/constants';
import type { GetServerSideProps, NextPage } from 'next';
import { ITherapistsWithParamResponse } from '@landing/types/home.page';
import ModalVideo from 'react-modal-video';
import { useClientSize } from '@landing/hooks';

interface IHomePageProps {
  therapistsWithParam: ITherapistsWithParamResponse;
}

const HomePage: NextPage<IHomePageProps> = ({ therapistsWithParam }) => {
  const [videoIsOpen, setVideoIsOpen] = useState(false);
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('s');

  return (
    <>
      <ModalVideo
        ratio={isMobile ? '8:14.2' : '16:9'}
        channel="custom"
        autoplay
        isOpen={videoIsOpen}
        url={
          isMobile
            ? '/assets/videos/whyWe.mp4'
            : '/assets/videos/whyWe_desk.mp4'
        }
        onClose={() => setVideoIsOpen(false)}
      />

      <MainLayout role="client">
        <ParallaxProvider>
          <Client
            setVideoState={setVideoIsOpen}
            therapistList={therapistsWithParam.data}
          />
        </ParallaxProvider>
      </MainLayout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<
  IHomePageProps
> = async () => {
  const res = await fetch(API_URL.getTherapistsWithParams);
  const therapistsWithParam: ITherapistsWithParamResponse = await res.json();

  return {
    props: { therapistsWithParam },
  };
};

export default HomePage;
